<template lang="pug">
.font-manager
  om-select#fontManager(
    style="flex-grow: 1"
    v-if="fonts.length"
    ref="fontManager"
    :options="fonts"
    placeholder="Select font"
    :value="value"
    v-model="selected"
    searchable
    @input="$emit('select', $event)"
  )
  om-button.ml-2(
    @click="$modal.show('custom-font-upload-v2', { origin: uid })"
    icon="upload"
    ghost
    iconOnly
  )
</template>
<script>
  import { UilUpload } from '@iconscout/vue-unicons';
  import GET_FONTS from '@/graphql/GetFonts.gql';
  import { mapGetters } from 'vuex';
  import WebFontLoader from 'webfontloader';
  import { getCdnUrl } from '@/config/cdn';
  import { getAccountIdFromCookie } from '@/util';
  import { nanoid } from 'nanoid';

  export default {
    components: { UilUpload },
    props: {
      uid: { type: String, default: () => nanoid(8) },
      value: {
        type: Object,
      },
    },
    data() {
      return {
        selected: null,
        fonts: [],
        loaded: false,
      };
    },
    computed: {
      ...mapGetters({
        userId: 'databaseId',
      }),
      gFonts() {
        return this.fonts.filter((font) => !font.custom);
      },
      customFonts() {
        return this.fonts.filter((font) => font.custom);
      },
    },
    watch: {
      loaded(n) {
        if (n) this.loadFonts();
      },
      value(n) {
        this.selected = n;
      },
    },
    created() {
      this.selected = this.value;
      this.getPreinstalledFonts();
      this.$bus.$on('customFontAdded', this.onFontUpload);
    },
    updated() {
      if (!this.value) return;

      let font = this.value;
      if (!this.value.hasOwnProperty('custom') && !this.value.hasOwnProperty('value')) {
        font = this.fonts.find(({ key }) => key === this.value.key);
      }
      this.selected = font;
      this.useFontFamily();
    },
    beforeDestroy() {
      this.$bus.$off('customFontAdded', this.onFontUpload);
    },
    methods: {
      async onFontUpload({ name, value, origin = null }) {
        if (origin === this.uid) {
          const uploaded = {
            key: value,
            value: name,
            custom: true,
          };
          await this.add(uploaded);

          this.selected = uploaded;
          this.$emit('select', uploaded);
          this.$modal.hide('custom-font-upload-v2');
        }
      },
      add(font) {
        this.fonts.push(font);
        return this.loadFonts();
      },
      async loadFonts() {
        const customFamilies = this.customFonts.map(({ value }) => value);
        const customUrls = this.customFonts.map(
          ({ key }) => `${getCdnUrl()}/customFonts/${getAccountIdFromCookie()}/${key}/${key}.css`,
        );
        WebFontLoader.load({
          google: {
            families: this.gFonts.map(({ value }) => value),
          },
          custom: {
            families: customFamilies,
            urls: customUrls,
          },
        });
      },
      useFontFamily() {
        this.$nextTick(() => {
          const options = this.$el.querySelectorAll('.select-option-value');
          options.forEach((element) => {
            element.style.fontFamily = `'${element.textContent}', sans-serif`;
          });
        });
      },
      async getPreinstalledFonts() {
        const {
          data: { fonts = {} },
        } = await this.$apollo.query({
          query: GET_FONTS,
        });

        this.fonts = Object.entries(fonts)
          .map(([key, { installedSubsets, preInstalled, family, custom = false }]) => {
            return installedSubsets || preInstalled || custom
              ? { key, value: family, custom }
              : null;
          })
          .filter((font) => !!font);

        this.loaded = true;
      },
    },
  };
</script>
<style scoped lang="sass">
  .font-manager
    display: flex
  .font-upload-icon
    margin-left: auto
    cursor: pointer
    flex: 0 0 30px
    display: flex
    align-items: center
    justify-content: center
</style>
