<template lang="pug">
.om-wizard-auto-personalize
  .justify-content-center.pb-6
    .text-center
      wizard-title.mb-6 {{ $t('onboarding.autoPersonalize.title') }}
      .color-previews.mt-2
        .color-preview-item(v-for="{ color, selected } in detectedColors")
          ColorChip(
            :loading="!(selectedMainTemplate && selectedColor && getContentsByBaseThemeId(baseThemeId) && themeKit)"
            :colorInHex="color"
            :selected="selected"
            @click="handleColorChange"
          )
    .row(:style="'margin-top: 2.25rem'")
      .col
        .wizard-theme-content.mx-auto
          TemplateFrameWrapper(@click="onOriginalStyle")
            template(#title)
              span {{ $t('onboarding.autoPersonalize.template.original.title') }}
            template(#template)
              TemplateFrame(
                uniqueSelector="custom-theme"
                @observable="addObservable($event.$el)"
                v-if="selectedMainTemplate && themeKit"
                @inited="updateDimensions"
                :dimensions="boxDimensions"
                allowSsr
                :template="selectedMainTemplate"
                @contentLoaded="contentLoaded"
                clearOnChange
                :hiddenFromTheLr="false"
              )
              .template-placeholder(v-else)
            template(#footer)
              span {{ $t('onboarding.autoPersonalize.template.original.footer') }}
          WizardArrow.arrow
          TemplateFrameWrapper(@click="onAutoPersonalize")
            template(#title)
              span {{ $t('onboarding.autoPersonalize.template.branded.title') }}
            template(#template)
              TemplateFrame(
                uniqueSelector="auto-detect"
                @observable="addObservable($event.$el)"
                v-if="selectedMainTemplate && selectedColor && getContentsByBaseThemeId(baseThemeId) && themeKit"
                @inited="updateDimensions"
                :dimensions="boxDimensions"
                allowSsr
                :template="selectedMainTemplate"
                @contentLoaded="onContentLoaded"
                clearOnChange
                :color="selectedColor"
                :staticContent="selectedContent"
                :hiddenFromTheLr="false"
              )
              .template-placeholder(v-else)
                AutoPersonalizeSkeleton
            template(#footer)
              span {{ $t('onboarding.autoPersonalize.template.branded.footer') }}
        template(v-if="isSuperAdmin")
          .d-flex.justify-content-center.mt-5.for-debug
            .row
              .col-3
                span SUPER ADMIN ONLY
                OmInput#url(:label="$t('url')" small v-model="url")
                OmButton.mt-2(small primary @click="fetchSite" :loading="loading") Fetch site
              .col-auto.mt-2
                span fonts:
                br
                code {{ modifiedPayload().fonts }}
                br
                span Custom fonts:
                br
                code {{ fontsToLoad.filter((f) => f.custom).map((f) => f.value) }}
                br
                span rounding:
                br
                code {{ modifiedPayload().rounding }}
                br
                span colors:
                br
                code {{  {mainColor: modifiedPayload().colors.mainColor, secondaryColors: modifiedPayload().colors.secondaryColors}  }}
</template>
<script>
  import runtimeConfig from '@/config/runtime';
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
  import { track } from '@/services/xray';
  import tinycolor from 'tinycolor2';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import ColorChip from '@/components/Wizard/ColorChip.vue';
  import TemplateFrameWrapper from '@/components/Wizard/TemplateFrameWrapper.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import WizardArrow from '@/components/Wizard/WizardArrow.vue';
  import AutoPersonalizeSkeleton from '@/components/SkeletonLoader/AutoPersonalizeSkeleton/AutoPersonalizeSkeleton.vue';
  import GET_AUTO_THEME from '@/graphql/GetAutoTheme.gql';
  import GET_FONTS from '@/graphql/GetFonts.gql';
  import UPDATE_FONTS from '@/graphql/UpdateFonts.gql';
  import UPDATE_PREFERRED_TEMPLATE_LANGUAGE from '@/graphql/UpdatePreferredTemplateLanguage.gql';
  import logoMixin from '@/mixins/logo';
  import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
  import ssrParamsMixin from '@/mixins/ssrParams';
  import personalizeMixin from '@/mixins/personalize';
  import previewParentMixin from '@/mixins/previewParent';
  import { getCdnUrl } from '@/config/cdn';
  import WebFontLoader from 'webfontloader';
  import navigationMixin from '../navigation';

  const MAX_COLORS = 8;

  export default {
    components: {
      WizardTitle,
      ColorChip,
      TemplateFrameWrapper,
      TemplateFrame,
      WizardArrow,
      AutoPersonalizeSkeleton,
    },
    mixins: [personalizeMixin, navigationMixin, logoMixin, ssrParamsMixin, previewParentMixin],
    data() {
      return {
        detectedColors: [],
        availableFonts: [],
        selectedColor: null,
        url: null,
        radiusValue: '',
        loading: false,
        autoPersonalizeClickable: false,
        images: [],
        endpoint: 'themekit-preview',
        colors: [],
        ourFonts: [],
        fontsToLoad: [],
      };
    },
    computed: {
      ...mapState(['preferredTemplateLanguage']),
      ...mapState('autoPersonalize', ['prevPreferredLanguage']),
      ...mapGetters(['isSuperAdmin', 'domains', 'databaseId']),
      ...mapGetters('autoPersonalize', ['getContentsByBaseThemeId']),
      selectedContent() {
        return this.getContentsByBaseThemeId(this.baseThemeId)[this.selectedColor];
      },
      themeKit() {
        return this.selectedMainTemplate.template.themeKit;
      },
    },
    watch: {
      async selectedMainTemplate(selected) {
        if (!selected) return;
        const { colors = [], fonts = [], radius = [] } = await this.getAutoTheme();
        this.colors = colors;
        this.setColors(colors);
        await this.loadFonts(fonts);
        this.setFonts(fonts);
        this.setRadius(radius);
        this.setAutoLogo();
        await this.cacheContents(colors);
        this.setColors(colors, true);
      },
    },
    async mounted() {
      this.removeUnusedParameters();
    },
    methods: {
      ...mapActions('autoPersonalize', ['preloadContents']),
      ...mapMutations('autoPersonalize', ['SET_PREV_PREFERRED_LANGUAGE']),
      contentLoaded() {
        this.autoPersonalizeClickable = true;
      },
      modifiedPayload(color) {
        const basePayload = JSON.parse(JSON.stringify(this.payload));

        // keep original logo on the left template preview
        delete this.payload.image;
        basePayload.colors.mainColor = color ? tinycolor(color).toHexString() : this.selectedColor;
        basePayload.fonts = this.fontsToLoad.map(({ key }) => key);
        basePayload.rounding = this.radiusValue;
        basePayload.image = this.usedImage;
        basePayload.colors.secondaryColors = getPaletteColors(this.selectedColor).slice(1);

        return basePayload;
      },
      removeUnusedParameters() {
        const params = new URLSearchParams(window.location.search);
        params.delete('color');
        params.delete('rounding');
        params.delete('mainFont');
        params.delete('secondaryFont');
        params.delete('customTheme');

        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      },
      setAutoLogo() {
        const lastDomain = this.domains.slice(-1)[0];
        if (!lastDomain) return;
        if (!this.hasAutoLogo) return;

        const autoLogoImage = this.images.find((image) => {
          return image.name.includes(lastDomain.domain);
        });

        if (!autoLogoImage) return;

        this.onUseImage(autoLogoImage);
      },
      onUseImage(image) {
        this.image = image;
        this.logo = this.getNameFromURL(image?.url);
      },
      setQueryStringParameter(name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
      },
      onAutoPersonalize() {
        if (!this.autoPersonalizeClickable) return;

        this.setQueryStringParameter('color', this.selectedColor);
        this.setQueryStringParameter('rounding', this.radiusValue);
        this.setQueryStringParameter('mainFont', this.fontsToLoad[0].key);
        this.setQueryStringParameter('secondaryFont', this.fontsToLoad[1].key);
        this.setQueryStringParameter(
          'language',
          this.prevPreferredLanguage || this.preferredTemplateLanguage,
        );
        track('auto-personalize-accept', { themeName: this.selectedCustomTheme.name });
        this.setLanguage(this.prevPreferredLanguage || this.preferredTemplateLanguage);
        this.next(null, {
          color: this.selectedColor,
          rounding: this.radiusValue,
          mainFont: this.fontsToLoad[0].key || undefined,
          secondaryFont: this.fontsToLoad[1].key || undefined,
          language: this.prevPreferredLanguage || this.preferredTemplateLanguage,
        });
      },
      onOriginalStyle() {
        track('auto-personalize-decline', { themeName: this.selectedCustomTheme.name });
        if (this.preferredTemplateLanguage !== 'en') {
          this.SET_PREV_PREFERRED_LANGUAGE(this.preferredTemplateLanguage);
        }
        this.setLanguage(this.selectedMainTemplate.locale);
        this.image = null;
        const { colors, fonts, rounding } = this.selectedMainTemplate.template.themeKit;
        this.next(null, {
          color: colors.mainColor,
          rounding,
          mainFont: fonts[0],
          secondaryFont: fonts[1],
          language: this.selectedMainTemplate.locale,
          originalTheme: 1,
        });
      },
      async setLanguage(languageCode) {
        if (this.preferredTemplateLanguage === 'en') return;

        await this.$apollo.mutate({
          mutation: UPDATE_PREFERRED_TEMPLATE_LANGUAGE,
          variables: {
            languageCode,
          },
        });
      },
      async fetchSite() {
        this.loading = true;
        try {
          const data = await this.getAutoTheme(this.url);
          if (!data) {
            this.loading = false;
            return;
          }
          const { colors = [], fonts = [], radius = [] } = data;
          this.setColors(colors);
          await this.loadFonts(fonts);
          this.setFonts(fonts);
          this.setRadius(radius);
          await this.cacheContents(colors, this.url);
          this.setColors(colors, true);
        } catch (e) {
          console.error(e);
        }

        this.loading = false;
      },
      async cacheContents(colors, url = null) {
        if (!this.selectedMainTemplate) return;

        const queries = colors.map((c) => {
          return {
            method: 'post',
            url: this.customEndpointUrl(),
            data: { baseTheme: true, themeKit: this.modifiedPayload(c) },
            params: {
              v: Date.now(),
              theme: this.themeKit.id,
              color: c,
              language: this.prevPreferredLanguage || this.preferredTemplateLanguage,
              account: this.databaseId,
            },
          };
        });

        await this.preloadContents({ queries, colors, baseThemeId: this.baseThemeId, url });
      },
      customEndpointUrl() {
        const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
        if (ssrUrl) {
          let url = `${ssrUrl}/${this.endpoint}/${this.selectedMainTemplate._id}`;
          if (this.shouldApplyPreferredLanguage) {
            url += `?language=${this.preferredTemplateLanguage}`;
          }

          return url;
        }
        return null;
      },
      async getAutoTheme(url = null) {
        try {
          const {
            data: { getAutoTheme },
          } = await this.$apollo.query({
            query: GET_AUTO_THEME,
            variables: {
              url,
            },
          });

          return getAutoTheme;
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'unable to fetch site',
          });
          return null;
        }
      },
      setColors(colors, setSelected) {
        if (!colors.length) return;

        const orderedColors = this.sortColorsBySaturation(colors);

        const uniqueColors = Array.from(
          new Set(orderedColors.map((c) => tinycolor(c).toHexString())),
        ).map((color, index) => ({
          color,
          selected: index === 0,
        }));

        this.detectedColors = uniqueColors.slice(0, MAX_COLORS);

        if (setSelected) {
          this.selectedColor = this.detectedColors[0].color;
        }
      },
      sortColorsBySaturation(colors) {
        return colors
          .map((color) => {
            const tc = tinycolor(color);
            const { s, v } = tc.toHsv();

            const isLight = v > 0.85;
            const isVivid = s > 0.5 && v > 0.3;

            return { color, saturation: s, isLight, isVivid };
          })
          .sort((a, b) => {
            if (a.isVivid && !b.isVivid) return -1;
            if (!a.isVivid && b.isVivid) return 1;
            if (a.isLight && !b.isLight) return 1;
            if (!a.isLight && b.isLight) return -1;
            return b.saturation - a.saturation;
          })
          .map((item) => item.color);
      },
      setFonts(fonts) {
        let [firstFont, secondFont] = fonts;

        const hasFirst = this.ourFonts.find((font) => font.key === firstFont);
        const hasSecond = this.ourFonts.find((font) => font.key === secondFont);

        if (!hasFirst) {
          firstFont = this.themeKit.fonts[0];
        }

        if (!hasSecond) {
          secondFont = this.themeKit.fonts[1];
        }

        if (hasSecond && !hasFirst) {
          firstFont = secondFont;
        }

        this.fontsToLoad = [
          this.ourFonts.find((font) => font.key === firstFont),
          this.ourFonts.find((font) => font.key === secondFont),
        ];

        this.loadTemplateFonts();
      },
      async loadTemplateFonts() {
        const googleFonts = this.fontsToLoad.filter((font) => !font.custom);
        const customFonts = this.fontsToLoad.filter((font) => font.custom);
        const customUrls = customFonts.map(
          (font) => `${getCdnUrl()}/customFonts/${this.databaseId}/${font.value}/${font.value}.css`,
        );
        const webFontConfig = {};

        if (googleFonts.length) {
          webFontConfig.google = {
            families: googleFonts.map(({ value }) => value),
          };
        }
        if (customFonts.length) {
          webFontConfig.custom = {
            families: customFonts.map(({ value }) => value),
            urls: customUrls,
          };
        }

        WebFontLoader.load(webFontConfig);
      },
      getMostCommonRadius(radius) {
        if (radius.length === 0) {
          return { mostCommonCategory: null, maxRadius: 0 };
        }

        const categories = {
          none: 0,
          small: 0,
          medium: 0,
          large: 0,
        };

        let maxRadius = 0;

        const onlyPxRadius = radius.filter(
          (item) => typeof item[0] === 'string' && item[0].endsWith('px'),
        );
        onlyPxRadius.forEach(([value, weight]) => {
          const numericValue = parseFloat(value);

          if (numericValue <= 2) {
            categories.none += weight;
          } else if (numericValue >= 3 && numericValue <= 10) {
            categories.small += weight;
          } else if (numericValue >= 11 && numericValue <= 20) {
            categories.medium += weight;
          } else {
            categories.large += weight;
          }

          if (numericValue > maxRadius) {
            maxRadius = numericValue;
          }
        });

        const mostCommonCategory = Object.keys(categories).reduce((a, b) =>
          categories[a] > categories[b] ? a : b,
        );

        return {
          mostCommonCategory,
          maxRadius,
        };
      },
      setRadius(radius) {
        if (!radius.length) {
          this.radiusValue = this.themeKit.rounding;
          return;
        }
        const { maxRadius, mostCommonCategory } = this.getMostCommonRadius(radius);
        console.log(
          'KEREKÍTÉS: ',
          'Ezeket találtuk: ',
          radius,
          'Maximum érték: ',
          maxRadius,
          'Leggyakoribb érték: ',
          mostCommonCategory,
        );
        this.radiusValue = mostCommonCategory || this.themeKit.rounding;
      },
      handleColorChange(e) {
        track('auto-personalize-color-change');
        this.detectedColors.forEach((colorObj) => {
          if (colorObj.color === e) {
            colorObj.selected = true;
            this.selectedColor = colorObj.color;
          } else {
            colorObj.selected = false;
          }
        });
      },
      async storeFonts(key, subsets, weights) {
        await this.$apollo.mutate({
          mutation: UPDATE_FONTS,
          variables: {
            key,
            subsets,
            weights,
          },
        });
      },
      async loadFonts(userFonts) {
        const {
          data: { fonts = {} },
        } = await this.$apollo.query({
          query: GET_FONTS,
        });
        this.ourFonts = Object.entries(fonts).map(
          ([key, { installedSubsets, family, subsets, variants, custom = false }]) => {
            return { key, value: family, subsets, weights: variants, installedSubsets, custom };
          },
        );

        const [firstFont, secondFont] = userFonts;

        this.availableFonts = Object.entries(fonts)
          .filter((font) => [firstFont, secondFont].includes(font[0]))
          .map(([key, { installedSubsets, family, subsets, variants }]) => {
            return { key, value: family, subsets, weights: variants, installedSubsets };
          });

        for (const { key, subsets, weights, preInstalled } of this.availableFonts) {
          if (preInstalled) continue;
          await this.storeFonts(key, subsets, weights);
        }
      },
    },
  };
</script>
<style lang="sass" scoped>
  .for-debug
    border: 3px dotted grey
  .color-previews
    display: flex
    gap: 12px
    justify-content: center
    margin-bottom: 36px
  .wizard-theme-content
    display: flex
    gap: 32px
    width: fit-content
    overflow: unset
    .template-placeholder
      aspect-ratio: 592/333
      width: 100%
      height: auto
    .template-thumbnail-box-wrapper
      width: 592px
      .template-thumbnail-iframe
        transform: scale(0.4625)
      ::v-deep .template-thumbnail-box
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
    .arrow
      z-index: 9999
      position: absolute
      transform: scale(1.3)
      top: 189px
      left: 541px
  @media screen and (max-width: 1199px)
    .wizard-theme-content
      .template-thumbnail-box-wrapper
        width: 24rem
      .arrow
        transform: scale(1)
        top: 110px
        left: 290px
</style>
